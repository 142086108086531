




import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../layouts/components/EntityCrud.vue";
import userModel from "../models/user.model";

@Component({
  components: { EntityCrud },
})
export default class Admins extends Vue {
  model: any = userModel;

  title = "Administradores";
  tableColumns = ["email", "name"];
  filter = { role: "ADMINISTRATOR" };

  formColumns = ["email", "name", "password"];
  defaultEntity = { role: "ADMINISTRATOR" };
}
